@import '~@vime/core/themes/default.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl py-2;
    }

    h2 {
        @apply text-xl py-1;
    }

    h3 {
        @apply text-lg;
    }
}

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
    font-size: 16px
}

body {
    margin: 0;
    font-family: "Futura", "Helvetica Neue", sans-serif;
}

.dialog-custom>mat-dialog-container {
    border-radius: 30px;
    background-color: #072333 !important;
}

canvas {
    height: 100%; /* we need these for full-screen effect */
    width: 100%;
}

.fb-button.mat-raised-button {
    background-color: #4267B2;
    color: #fff;
}

.apple-button.mat-raised-button {
    background-color: #000;
    color: #fff;
}